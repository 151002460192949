import React, { useState, useEffect } from 'react';
import Nabvar from '../Components/Navbar/Navbar';
import Demo from '../Pages/Demo/Demo'; 
export default function IndexLayout() {


    useEffect(() => {
        const link = document.createElement('link');
        link.href = '/tema.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link); 
        // Cleanup function to remove the link when the component is unmounted
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    return (
        <div className='section-bg-color'>
             
            <Nabvar />
            <div className='espaciobg'>

            </div>
            <Demo />
        </div>
    );
}
