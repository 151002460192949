import React, { useEffect, useState, useRef } from 'react';
import './Footer.css'
import { Link as Anchor } from 'react-router-dom';
import useProductsStore from '../productsStore';
import useContactoStore from '../contactoStore';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
export default function Footer() {
    const contactoData = useContactoStore(state => state.contactoData)
    const [contactos, setContactos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const productsGlobal = useProductsStore((state) => state.productsSet); 

    useEffect(() => {
        cargarContacto();

    }, [contactos]);


    const cargarContacto = () => {
        setContactos(contactoData[0]);
    };



    return (
        <div className='FooterContain'>

            <div className='footerText'>
                <h3>Contacto</h3>
                {
                    contactos.email && (
                        <Anchor to={`mailto:${contactos.email}`} target="_blank">{contactos.email}</Anchor>)
                }
                {
                    contactos.telefono && (
                        <Anchor to={`tel:${contactos.telefono}`} target="_blank">{contactos.telefono}</Anchor>)}
                {
                    contactos.direccion && (
                        <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contactos.direccion)}`} target="_blank">
                            {contactos.direccion}</Anchor>
                    )
                }

            </div>
            <div className='footerText'>
                <div className='socials'>
                    {
                        contactos.telefono && (
                            <Anchor to={`https://api.whatsapp.com/send?phone=${contactos.telefono}&text=${encodeURIComponent('Hola, quiero hacer reserva...')}:`} target="_blank">
                                <FontAwesomeIcon icon={faWhatsapp} /> <small> Whatsapp </small>
                            </Anchor>)
                    }
                    {
                        contactos.instagram && (
                            <Anchor to={contactos.instagram} target="_blank">
                                <FontAwesomeIcon icon={faInstagram} /> <small> Instagram</small>
                            </Anchor>
                        )
                    }
                    {
                        contactos.facebook && (

                            <Anchor to={contactos.facebook} target="_blank">
                                <FontAwesomeIcon icon={faFacebook} /> <small> Facebook</small>
                            </Anchor>)
                    }


                </div>
            </div>

            <div className='footerText'>
                <h3>Categorías</h3>
                {
                    categorias?.map((item, index) => (
                        <Anchor to='' key={index}>{item?.categoria?.slice(0, 30)}</Anchor>
                    ))
                }
            </div>
            <div className='footerText'>


                <div className="flex flex-col justify-between gap-3 py-6 text-sm font-medium lg:flex-row lg:items-center">
                    <div className="grow">
                        <p>
                            <Anchor to={`/dashboard`} > Login </Anchor>
                        </p>
                        {/*
                        <p>
                            <Anchor to='' >Crear tu negocio</Anchor>
                        </p> 
                        <p><Anchor to=''> Términos de servicio</Anchor> | 
                        <Anchor to=''> Política de privacidad</Anchor>
                        </p>*/}
                        <br />
                        <small> By Wepass.cl 📍 🇨🇱  </small>

                        <br />
                        <br />
                    </div>



                </div>
            </div>
        </div>
    )
}
