import React, { useEffect, useState, useRef } from 'react'; 
import './Profile.css'
import { Link as Anchor } from 'react-router-dom';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from '../logo'; 
import useConfigStore from '../configStore';

import useContactoStore from '../contactoStore';  

export default function Profile() {
    const contactoData = useContactoStore(state => state.contactoData)
    const [contactos, setContactos] = useState([]);
    const [bio, setBio] = useState('');
    const configData = useConfigStore(state => state.configData)

    useEffect(() => {
        cargarContacto(); 
        obtenerConfiguracion();
    }, [configData, contactoData]);

// Obtener configuración inicial
const obtenerConfiguracion = async () => { 
 
            setBio(  
                configData.bio || []
            ); 
};

    const cargarContacto = () => { 
                setContactos(contactoData[0]); 
    };

    return (
        <div className='profileContain'>   
        <Logo />
            <h2>{contactos.nombre}</h2>
            <div className='profileText'>{  
            // dar formato a bio para reemplazar los saltos de línea con etiquetas <br />
            bio.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
                   ))
        
        }</div>
            <div className='socials'>

            {
                        contactos.telefono && (
                            <Anchor to={`https://api.whatsapp.com/send?phone=${contactos.telefono}&text=${encodeURIComponent('Hola, quiero hacer reserva...')}:`} target="_blank">
                                <FontAwesomeIcon icon={faWhatsapp} />  
                            </Anchor>)
                    }
                    {
                        contactos.instagram && (
                            <Anchor to={contactos.instagram} target="_blank">
                                <FontAwesomeIcon icon={faInstagram} /> 
                            </Anchor>
                        )
                    }
                    {
                        contactos.facebook && (

                            <Anchor to={contactos.facebook} target="_blank">
                                <FontAwesomeIcon icon={faFacebook} />  
                            </Anchor>)
                    }


            </div>
            <div className='profileText'>
                <Anchor to={`mailto:${contactos.email}`} target="_blank">{contactos.email}</Anchor>
                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contactos.direccion)}`} target="_blank">{contactos.direccion}</Anchor>

            </div>
        </div>
    )
}
